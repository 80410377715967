// src/api/parseExchangeRates.ts

const parseExchangeRates = (file: any) => {
    const lines = file.split('\n');
    const currencies = lines.slice(2);

    const exchangeRates = currencies.map((currency: string) => {
        const values = currency.split('|');
        return {
            country: values[0],
            currency: values[1],
            amount: parseFloat(values[2]),
            code: values[3],
            rate: parseFloat(values[4])
        };
    });
    return exchangeRates;
};

export default parseExchangeRates;
