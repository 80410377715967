import React from 'react';
import CurrencyExchange from './components/CurrencyExchange';

const App: React.FC = () => {
    return (
        <div>
            <CurrencyExchange />
        </div>
    );
};

export default App;