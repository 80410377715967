
import parseExchangeRates from './parseExchangeRates';
import axios from "axios";
const fetchExchangeRates = async () => {
    try {
        let response;

        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
            // Načtení souboru lokálně
            response = await axios.get('http://'+window.location.hostname+':3001/remote-file');
        } else {
            // Načtení souboru ze serveru
            response = await axios.get('https://'+window.location.hostname+'/get_file.php');
        }

        response = parseExchangeRates(response.data)
        return  response;
    } catch (error) {
        console.log(error)
        throw new Error('Chyba při načítání směnných kurzů');
    }
};

export { fetchExchangeRates };
