import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const Label = styled.label`
  margin-bottom: 10px;
`;

export const Select = styled.select`
  padding: 8px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;

export const Result = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
`;

export const AmountInput = styled.input`
  padding: 8px;
  margin-bottom: 10px;
`;
