// src/components/CurrencyExchange.tsx

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchExchangeRates } from '../api/fetchExchangeRates';
import { Container, Title, Form, Label, Select, Button, Result } from './styles';

const CurrencyExchange: React.FC = () => {
    const { data, isLoading, isError } = useQuery('exchangeRates', fetchExchangeRates);
    const [amount, setAmount] = useState<number>(0);
    const [selectedCurrency, setSelectedCurrency] = useState<string>('');
    const [result, setResult] = useState<number | null>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedCurrency || amount <= 0) return;

        const selectedRate = data.find((currency: { code: string; }) => currency.code === selectedCurrency)?.rate;
        if (!selectedRate) return;

        const convertedAmount = amount / selectedRate;
        setResult(convertedAmount);
    };

    return (
        <Container>
            <Title>Aplikace pro směnu měn</Title>
            {isLoading && <p>Načítání...</p>}
            {isError && <p>Chyba při načítání dat</p>}
            {data && (
                <Form onSubmit={handleSubmit}>
                    <Label>
                        Množství (CZK):
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                        />
                    </Label>
                    <Label>
                        Vyberte měnu:
                        <Select
                            value={selectedCurrency}
                            onChange={(e) => setSelectedCurrency(e.target.value)}
                        >
                            <option value="">Vyberte měnu</option>
                            {data.map((currency: any, index: any) => (
                                <option key={index} value={currency.code}>{currency.currency} ({currency.code})</option>
                            ))}
                        </Select>
                    </Label>
                    <Button type="submit">Převést</Button>
                </Form>
            )}
            {result !== null && (
                <Result>Výsledek: {result.toFixed(2)} {selectedCurrency}</Result>
            )}
        </Container>
    );
};

export default CurrencyExchange;
